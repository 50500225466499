import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import ActiveUsers from '../components/active-users'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Parkup - La forma más económica de estacionar</title>
        <meta property="og:title" content="Parkup - La forma más económica de estacionar" />
      </Helmet>
      <section className="home-hero">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="pastedImage"
              src="/playground_assets/logo_parkup-1500h.png"
              className="home-logo"
            />
            <div className="home-links">
              <span className="Link">Beneficios</span>
              <span className="Link">Buscar y Reservar</span>
              <span className="Link">Estaciona fácil</span>
              <span className="Link">Ubicaciones</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <img
                  alt="pastedImage"
                  src="/playground_assets/logo_parkup-1500h.png"
                  className="home-logo1"
                />
                <div className="home-links1">
                <span className="Link">Beneficios</span>
              <span className="Link">Buscar y Reservar</span>
              <span className="Link">Estaciona fácil</span>
              <span className="Link">Ubicaciones</span>
                </div>
              </div>
              <div className="home-right">
                <div className="home-get-started">
                  <span className="home-text06">Descargar APP</span>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <DangerousHTML
              html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
            ></DangerousHTML>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text07">La forma más económica de estacionar</h1>
          <div className="home-get-started1">
            <span className="home-text08">Descargar APP</span>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <img
              alt="pastedImage"
              src="/playground_assets/map2-1400w.png"
              loading="lazy"
              className="home-image"
            />
          </div>
        </div>
      </section>
      <section className="home-features">
        <div className="home-title">
          <span className="home-text09">Beneficios</span>
          <span className="home-text10">
            Encuentra fácilmente una plaza de estacionamiento cerca de tu
            destino con ParkUP
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container01">
            <div className="card">
              <div className="home-container02">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M504 466q44 12 73 24t61 33 49 53 17 76q0 62-41 101t-109 51v92h-128v-92q-66-14-109-56t-47-108h94q8 90 126 90 62 0 89-23t27-53q0-72-128-104-200-46-200-176 0-58 42-99t106-55v-92h128v94q66 16 101 60t37 102h-94q-4-90-108-90-52 0-83 22t-31 58q0 58 128 92z"></path>
                </svg>
                <div className="home-container03"></div>
              </div>
              <span className="home-text11">Ahorro de dinero</span>
              <span className="home-text12">
                Al utilizar el servicio de ParkUP, podrías ahorrar tiempo y
                dinero en el costo de estacionamiento en comparación con otros
                estacionamientos convencionales. Además, no tendrás que
                preocuparte por encontrar un lugar de estacionamiento en la
                calle.
              </span>
            </div>
            <div className="home-publish card">
              <div className="home-container04">
                <svg viewBox="0 0 1024 1024" className="home-icon04">
                  <path d="M512 256q-34 0-60-26t-26-60 26-59 60-25 60 25 26 59-26 60-60 26zM874 256l22 86q-100 28-256 42v554h-86v-256h-84v256h-86v-554q-156-14-256-42l22-86q156 42 362 42t362-42z"></path>
                </svg>
              </div>
              <span className="home-text13">Comodidad</span>
              <span className="home-text14">
                Con la aplicación de ParkUP, puedes reservar tu plaza de
                estacionamiento con anticipación y pagar en línea sin la
                necesidad de buscar efectivo o tickets de estacionamiento.
              </span>
            </div>
          </div>
          <div className="home-container05">
            <div className="card home-analyze">
              <div className="home-container06">
                <svg viewBox="0 0 1024 1024" className="home-icon06">
                  <path d="M1024 201.143v621.714c0 14.857-9.143 28-22.286 33.714-4.571 1.714-9.714 2.857-14.286 2.857-9.714 0-18.857-3.429-25.714-10.857l-230.286-230.286v94.857c0 90.857-73.714 164.571-164.571 164.571h-402.286c-90.857 0-164.571-73.714-164.571-164.571v-402.286c0-90.857 73.714-164.571 164.571-164.571h402.286c90.857 0 164.571 73.714 164.571 164.571v94.286l230.286-229.714c6.857-7.429 16-10.857 25.714-10.857 4.571 0 9.714 1.143 14.286 2.857 13.143 5.714 22.286 18.857 22.286 33.714z"></path>
                </svg>
              </div>
              <span className="home-text15">Seguridad</span>
              <span className="home-text16">
                Los estacionamientos de ParkUP están vigilados y protegidos las
                24 horas del día, lo que garantiza la seguridad de tu vehículo
                mientras esté estacionado.
              </span>
            </div>
            <div className="card">
              <div className="home-container07">
                <svg viewBox="0 0 1024 1024" className="home-icon08">
                  <path d="M292.571 0c9.714 0 18.286 8.571 18.286 18.286v841.143c0 6.857-4 13.143-9.714 16l-274.286 146.286c-2.857 1.714-5.714 2.286-8.571 2.286-9.714 0-18.286-8.571-18.286-18.286v-841.143c0-6.857 4-13.143 9.714-16l274.286-146.286c2.857-1.714 5.714-2.286 8.571-2.286zM1005.714 0c9.714 0 18.286 8.571 18.286 18.286v841.143c0 6.857-4 13.143-9.714 16l-274.286 146.286c-2.857 1.714-5.714 2.286-8.571 2.286-9.714 0-18.286-8.571-18.286-18.286v-841.143c0-6.857 4-13.143 9.714-16l274.286-146.286c2.857-1.714 5.714-2.286 8.571-2.286zM365.714 0c2.857 0 5.714 0.571 8 1.714l292.571 146.286c6.286 3.429 10.286 9.714 10.286 16.571v841.143c0 9.714-8.571 18.286-18.286 18.286-2.857 0-5.714-0.571-8-1.714l-292.571-146.286c-6.286-3.429-10.286-9.714-10.286-16.571v-841.143c0-9.714 8.571-18.286 18.286-18.286z"></path>
                </svg>
              </div>
              <span className="home-text17">Amplia disponibilidad</span>
              <span className="home-text18">
                ParkUP cuenta con múltiples estacionamientos en distintas zonas
                de la ciudad, lo que significa que hay muchas opciones para
                encontrar una plaza de estacionamiento cerca de tu destino.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="home-statistics">
        <div className="home-container08">
          <ActiveUsers Caption="— Usuarios" Statistic="90K"></ActiveUsers>
          <ActiveUsers Caption="— Reservas" Statistic="10M"></ActiveUsers>
          <ActiveUsers
            Caption="— Parking"
            Statistic="100"
          ></ActiveUsers>
          <ActiveUsers Caption="— Abonos" Statistic="30M"></ActiveUsers>
        </div>
      </section>

      
      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container09">
            <div className="home-left1">
              <span className="home-text21 title">
                Busca, reserva y paga tu estacionamiento fácilmente.
              </span>
              <span className="home-text22">
                La aplicación ParkUp permite a los conductores encontrar
                fácilmente espacios de estacionamiento disponibles en diferentes
                ubicaciones, lo que reduce el tiempo que se gasta en la búsqueda
                de estacionamiento. Además, ofrece una interfaz de usuario
                sencilla y fácil de usar para la reserva y el pago de plazas de
                estacionamiento, lo que simplifica aún más el proceso de
                estacionamiento. En resumen, ParkUp ahorra tiempo y simplifica
                la búsqueda de estacionamiento para conductores.
              </span>
              <div className="home-get-started2 template-button">
                <span className="home-text23">Descargar APP</span>
              </div>
            </div>
            <div className="home-image-container">
              <img
                alt="pastedImage"
                src="/playground_assets/04.png"
                className="home-cards-image"
              />
            </div>
          </div>
        </div>
        <div className="home-banner-advanced">
          <div className="home-centered-container">
            <div className="home-image-container1">
              <img
                alt="pastedImage"
                src="/playground_assets/02.png"
                className="home-cards-image"
              />
            </div>
            <div className="home-right1">
              <h2 className="home-text24 title">
                Estaciona fácil, sin contacto y reserva previa
              </h2>
              <div className="home-category">
                <span className="home-text25">
                  La facilidad de uso de la apertura sin contacto y la reserva
                  previa de estacionamiento permiten a los conductores encontrar
                  y acceder a espacios de estacionamiento de manera rápida y
                  segura. Con la reserva previa, los conductores pueden asegurar
                  un espacio de estacionamiento antes de llegar al lugar,
                  evitando así la frustración y el tiempo perdido en la búsqueda
                  de estacionamiento. Además, la apertura sin contacto permite
                  una entrada y salida rápida y sin problemas del
                  estacionamiento, eliminando la necesidad de utilizar tickets o
                  tener contacto con dispositivos de entrada. Esto no solo
                  ahorra tiempo, sino que también ayuda a reducir la propagación
                  de enfermedades contagiosas al minimizar el contacto físico
                  entre los conductores y los dispositivos del estacionamiento.
                </span>
              </div>
              <div className="home-category1"></div>
            </div>
          </div>
        </div>
        <div className="home-banner">
          <div className="home-container10">
            <div className="home-left2">
              <h2 className="home-text26 title">
                Estacionamiento en múltiples ubicaciones
              </h2>
              <span className="home-text27">
                ParkUp permite encontrar y reservar fácilmente estacionamiento
                en múltiples ubicaciones de la ciudad, lo que garantiza una
                experiencia sin problemas al estacionar. La interfaz de usuario
                fácil de usar y la apertura sin contacto hacen el proceso rápido
                y conveniente.
              </span>
              <div className="home-get-started3 template-button">
                <span className="home-text28">Descargar APP</span>
              </div>
            </div>
            <div className="home-image-container2">
              <img
                alt="pastedImage"
                src="/playground_assets/02.png"
                className="home-cards-image"
              />
            </div>
          </div>
        </div>
      
      </section>

      
      <section className="home-quote-container">
        <div className="home-quote">
          <span className="home-message">
            &quot;ParkUP es conveniente y seguro. Reservo mi estacionamiento con
            anticipación, ahorrando tiempo y dinero. Los estacionamientos están
            vigilados. ¡Recomendado!&quot;
          </span>
          <div className="home-author">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fGdpcmx8ZW58MHx8fHwxNjY0ODA5MjE1&amp;ixlib=rb-1.2.1&amp;w=200"
              className="home-avatar"
            />
            <span className="home-quote1">
              <span className="home-text19">—  Andra</span>
              <span>, Cliente Parkup</span>
            </span>
          </div>
        </div>
      </section>
      <footer className="home-footer">
          <img
            alt="logo"
            src="/playground_assets/logo_parkup-1500h.png"
            className="home-image1"
          />
          <span className="home-text29">
            © 2023 Parkup, All Rights Reserved.
          </span>
          <div className="home-icon-group">
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon10">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon12">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon14">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </footer>
    
    </div>
  )
}

export default Home
