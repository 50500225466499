import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import appleLogo from './apple.png';
import androidLogo from './google.png';
import './download.css'

const AppDownload = () => {
  const handleAndroidDownload = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.parkup';
  };

  const handleIOSDownload = () => {
    window.location.href = 'https://apps.apple.com/us/app/parkup/id6476673838';
  };

  const handleWebOpen = () => {
    window.open('https://www.ejemplo.com', '_blank');
  };

  return (
    <div className="body">
    
     
        <button onClick={handleAndroidDownload}>
          <img src={androidLogo} alt="Android Logo" className="logo" />
        
        </button>
    
    
        <button onClick={handleIOSDownload}>
          <img src={appleLogo} alt="Apple Logo" className="logo" />
        </button>
      
  
    </div>
  );
};

export default AppDownload;
