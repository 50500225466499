import React from 'react'

import DangerousHTML from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import ActiveUsers from '../components/active-users'
import './home.css'

const Home = (props) => {
    return (
        <div className="home-container">
            <Helmet>
                <title>Parkup - La forma más económica de estacionar</title>
                <meta property="og:title" content="Parkup - La forma más económica de estacionar" />
            </Helmet>


            <section >
                <div className={"policy"}>
                <h1>POLÍTICA DE PRIVACIDAD </h1>
<br></br>
<br></br>
                <p>Última actualización: 18 de enero de 2024 </p>
                <br></br>

                <p>Este aviso de privacidad para Mobility as a Service SpA ("nosotros", "nos" o "nuestro"), describe cómo y por qué podemos recopilar, almacenar, usar y/o compartir ("procesar") su información cuando utiliza nuestros servicios ("Servicios"), como cuando: </p>

                <p>Descargue y utilice nuestra aplicación móvil (Parkup), o cualquier otra aplicación nuestra que se vincule a este aviso de privacidad.</p>

                <p>Interactuar con nosotros de otras maneras relacionadas, incluidas las ventas, el marketing o los eventos. </p>

                <p>¿Preguntas o inquietudes?</p>

                <p>La lectura de este aviso de privacidad le ayudará a comprender sus derechos y opciones de privacidad. Si no está de acuerdo con nuestras políticas y prácticas, no utilice nuestros Servicios. Si aún tiene alguna pregunta o inquietud, comuníquese con nosotros al email contacto@parkup.cl. </p>

                <p>RESUMEN DE LOS PUNTOS CLAVE </p>

                <p>Este resumen proporciona los puntos clave de nuestro aviso de privacidad, pero puede encontrar más detalles sobre cualquiera de estos temas haciendo clic en el enlace que sigue a cada punto clave o utilizando nuestra tabla de contenido a continuación para encontrar la sección que está buscando. </p>

                <p>¿Qué información personal tratamos? Cuando visita, utiliza o navega por nuestros Servicios, podemos procesar información personal en función de cómo interactúe con nosotros y los Servicios, las elecciones que realice y los productos y funciones que utilice. Obtenga más información sobre la información personal que nos revela. </p>

                <p>¿Procesamos información personal confidencial? Podemos procesar información personal confidencial cuando sea necesario con su consentimiento o según lo permita la ley aplicable. Obtenga más información sobre la información confidencial que procesamos. </p>

                <p>¿Recibimos información de terceros? Podemos recibir información de bases de datos públicas, socios de marketing, plataformas de redes sociales y otras fuentes externas. Obtenga más información sobre la información recopilada de otras fuentes. </p>

                <p>¿Cómo procesamos su información? Procesamos su información para proporcionar, mejorar y administrar nuestros Servicios, comunicarnos con usted, para la seguridad y la prevención del fraude, y para cumplir con la ley. También podemos procesar su información para otros fines con su consentimiento. Procesamos su información sólo cuando tenemos una razón legal válida para hacerlo. Obtenga más información sobre cómo procesamos su información. </p>

                <p>¿En qué situaciones y con qué partes compartimos información personal? Podemos compartir información en situaciones específicas y con terceros específicos. Obtenga más información sobre cuándo y con quién compartimos su información personal. </p>

                <p>¿Cómo mantenemos su información segura? Contamos con procesos y procedimientos organizativos y técnicos para proteger su información personal. Sin embargo, no se puede garantizar que ninguna transmisión electrónica a través de Internet o tecnología de almacenamiento de información sea 100% segura, por lo que no podemos prometer ni garantizar que los piratas informáticos, ciberdelincuentes u otros terceros no autorizados no puedan vencer nuestra seguridad y recopilar, acceder, robar o modificar indebidamente su información. Obtenga más información sobre cómo mantenemos su información segura. </p>

                <p>¿Cuáles son sus derechos? Dependiendo de dónde se encuentre geográficamente, la ley de privacidad aplicable puede significar que tiene ciertos derechos con respecto a su información personal. Obtén más información sobre tus derechos de privacidad. </p>

                <p>¿Cómo ejercer sus derechos? La forma más fácil de ejercer sus derechos es visitando<a href="https://www.parkup.cl/" > https://www.parkup.cl</a> o poniéndose en contacto con nosotros. Consideraremos y actuaremos sobre cualquier solicitud de acuerdo con las leyes de protección de datos aplicables. </p>

                <p>¿Desea obtener más información sobre lo que hacemos con la información que recopilamos? Revise el aviso de privacidad en su totalidad. </p>

                <p>1. ¿QUÉ INFORMACIÓN RECOPILAMOS? </p>

                <p>Información personal que usted nos revela </p>

                <p>En resumen: Recopilamos la información personal que usted nos proporciona. </p>

                <p>Recopilamos la información personal que usted nos proporciona voluntariamente cuando se registra en los Servicios, expresa su interés en obtener información sobre nosotros o nuestros productos y Servicios, cuando participa en actividades en los Servicios o cuando se pone en contacto con nosotros. </p>

                <p>Información personal proporcionada por usted. La información personal que recopilamos depende del contexto de sus interacciones con nosotros y los Servicios, las elecciones que realice y los productos y funciones que utilice. La información personal que recopilamos puede incluir lo siguiente: </p>

                <p>Nombres </p>

                <p>Rut</p>

                <p>Números de teléfono </p>

                <p>Patente del Vehículo</p>

                <p>Direcciones de correo electrónico </p>

                <p>Números de tarjetas de débito/crédito </p>

                <p>Información sensible. Cuando sea necesario, con su consentimiento o según lo permita la ley aplicable, procesamos las siguientes categorías de información confidencial: </p>

                <p>Datos financieros </p>

                <p>Datos biométricos </p>

                <p>Datos de pago. Podemos recopilar los datos necesarios para procesar su pago si realiza compras, como su número de instrumento de pago y el código de seguridad asociado con su instrumento de pago. Todos los datos de pago son almacenados por Kushki. Puede encontrar su(s) enlace(s) de aviso de privacidad aquí: https://www.kushkipagos.com/legal. </p>

                <p>Datos de la aplicación. Si utiliza nuestra(s) aplicación(es), también podemos recopilar la siguiente información si decide proporcionarnos acceso o permiso: </p>

                <p>Información de geolocalización. Podemos solicitar acceso o permiso para rastrear información basada en la ubicación de su dispositivo móvil, ya sea de forma continua o mientras utiliza nuestra(s) aplicación(es) móvil(es), para proporcionar ciertos servicios basados en la ubicación. Si desea cambiar nuestro acceso o permisos, puede hacerlo en la configuración de su dispositivo. </p>

                <p>Acceso a dispositivos móviles. Podemos solicitar acceso o permiso a ciertas funciones de su dispositivo móvil, incluido el bluetooth, los mensajes SMS y otras funciones de su dispositivo móvil. Si desea cambiar nuestro acceso o permisos, puede hacerlo en la configuración de su dispositivo. </p>

                <p>Datos de dispositivos móviles. Recopilamos automáticamente información del dispositivo (como el ID, el modelo y el fabricante de su dispositivo móvil), el sistema operativo, la información de la versión y la información de configuración del sistema, los números de identificación del dispositivo y la aplicación, el tipo y la versión del navegador, el modelo de hardware, el proveedor de servicios de Internet y/o el operador de telefonía móvil, y la dirección de Protocolo de Internet (IP) (o servidor proxy). Si está utilizando nuestra(s) aplicación(es), también podemos recopilar información sobre la red telefónica asociada con su dispositivo móvil, el sistema operativo o la plataforma de su dispositivo móvil, el tipo de dispositivo móvil que utiliza, el ID de dispositivo único de su dispositivo móvil e información sobre las funciones de la(s) aplicación(es) a la(s) que accedió. </p>

                <p>Notificaciones push. Es posible que le solicitemos que le enviemos notificaciones automáticas con respecto a su cuenta o a ciertas funciones de la(s) aplicación(es). Si desea optar por no recibir este tipo de comunicaciones, puede desactivarlas en la configuración de su dispositivo. </p>

                <p>Esta información es necesaria principalmente para mantener la seguridad y el funcionamiento de nuestra(s) aplicación(es), para la resolución de problemas y para nuestros fines internos de análisis e informes. </p>

                <p>Toda la información personal que nos proporcione debe ser verdadera, completa y precisa, y debe notificarnos cualquier cambio en dicha información personal. </p>

                <p>Información recopilada automáticamente </p>

                <p>En resumen: Parte de la información, como su dirección de Protocolo de Internet (IP) y/o las características del navegador y del dispositivo, se recopila automáticamente cuando visita nuestros Servicios. </p>

                <p>Recopilamos automáticamente cierta información cuando visita, utiliza o navega por los Servicios. Esta información no revela su identidad específica (como su nombre o información de contacto), pero puede incluir información sobre el dispositivo y el uso, como su dirección IP, las características del navegador y del dispositivo, el sistema operativo, las preferencias de idioma, las URL de referencia, el nombre del dispositivo, el país, la ubicación, la información sobre cómo y cuándo utiliza nuestros Servicios y otra información técnica. Esta información es necesaria principalmente para mantener la seguridad y el funcionamiento de nuestros Servicios, y para nuestros fines internos de análisis e informes. </p>

                <p>Al igual que muchas empresas, también recopilamos información a través de cookies y tecnologías similares. </p>

                <ul>
                    <li><p>La información que recopilamos incluye: </p>

                    </li>
                    <ul>
                        <li><p>Datos de registro y uso. Los datos de registro y uso son información relacionada con el servicio, el diagnóstico, el uso y el rendimiento que nuestros servidores recopilan automáticamente cuando accede o utiliza nuestros Servicios y que registramos en archivos de registro. Dependiendo de cómo interactúe con nosotros, estos datos de registro pueden incluir su dirección IP, información del dispositivo, tipo de navegador e información sobre su actividad en los Servicios (como las marcas de fecha/hora asociadas con su uso, páginas y archivos vistos, búsquedas y otras acciones que realice, como las funciones que utiliza), información de eventos del dispositivo (como la actividad del sistema, informes de errores (a veces denominados "volcados de memoria") y configuración de hardware). </p>

                        </li>
                        <li><p>Datos del dispositivo. Recopilamos datos del dispositivo, como información sobre su computadora, teléfono, tableta u otro dispositivo que utilice para acceder a los Servicios. Dependiendo del dispositivo utilizado, estos datos del dispositivo pueden incluir información como su dirección IP (o servidor proxy), números de identificación del dispositivo y de la aplicación, ubicación, tipo de navegador, modelo de hardware, proveedor de servicios de Internet y/o operador de telefonía móvil, sistema operativo e información de configuración del sistema. </p>

                        </li>
                        <li><p>Datos de ubicación. Recopilamos datos de ubicación, como información sobre la ubicación de su dispositivo, que puede ser precisa o imprecisa. La cantidad de información que recopilamos depende del tipo y la configuración del dispositivo que utilice para acceder a los Servicios. Por ejemplo, podemos utilizar GPS y otras tecnologías para recopilar datos de geolocalización que nos indiquen su ubicación actual (en función de su dirección IP). Puede optar por no permitirnos recopilar esta información, ya sea negando el acceso a la información o deshabilitando la configuración de ubicación en su dispositivo. Sin embargo, si opta por no participar, es posible que no pueda utilizar ciertos aspectos de los Servicios. </p>

                        </li>
                    </ul>

                </ul>

                <p>Información recopilada de otras fuentes </p>

                <p>En resumen: Podemos recopilar datos limitados de bases de datos públicas, socios de marketing y otras fuentes externas. </p>

                <p>Con el fin de mejorar nuestra capacidad para proporcionarle marketing, ofertas y servicios relevantes y actualizar nuestros registros, podemos obtener información sobre usted de otras fuentes, como bases de datos públicas, socios de marketing conjunto, programas de afiliados, proveedores de datos y de otros terceros. Esta información incluye direcciones postales, cargos, direcciones de correo electrónico, números de teléfono, datos de intención (o datos de comportamiento del usuario), direcciones de Protocolo de Internet (IP), perfiles de redes sociales, URL de redes sociales y perfiles personalizados, con fines de publicidad dirigida y promoción de eventos. </p>

                <p>2. ¿CÓMO PROCESAMOS SU INFORMACIÓN? </p>

                <p>En resumen: Procesamos su información para proporcionar, mejorar y administrar nuestros Servicios, comunicarnos con usted, para la seguridad y la prevención del fraude, y para cumplir con la ley. También podemos procesar su información para otros fines con su consentimiento. </p>

                <ul>
                    <li><p>Procesamos su información personal por una variedad de razones, dependiendo de cómo interactúe con nuestros Servicios, que incluyen: </p>

                    </li>
                    <ul>
                        <li><p>Para facilitar la creación y autenticación de cuentas y administrar cuentas de usuario. Podemos procesar su información para que pueda crear e iniciar sesión en su cuenta, así como para mantenerla en funcionamiento. </p>

                        </li>
                        <li><p>Prestar y facilitar la prestación de servicios al usuario. Podemos procesar su información para brindarle el servicio solicitado. </p>

                        </li>
                        <li><p>Para responder a las consultas de los usuarios/ofrecer soporte a los usuarios. Podemos procesar su información para responder a sus consultas y resolver cualquier problema potencial que pueda tener con el servicio solicitado. </p>

                        </li>
                        <li><p>Para enviarle información administrativa. Podemos procesar su información para enviarle detalles sobre nuestros productos y servicios, cambios en nuestros términos y políticas, y otra información similar. </p>

                        </li>
                        <li><p>Para solicitar comentarios. Podemos procesar su información cuando sea necesario para solicitar comentarios y para comunicarnos con usted sobre su uso de nuestros Servicios. </p>

                        </li>
                        <li><p>Para enviarle comunicaciones de marketing y promocionales. Podemos procesar la información personal que nos envía para nuestros fines de marketing, si esto está de acuerdo con sus preferencias de marketing. Puede optar por no recibir nuestros correos electrónicos de marketing en cualquier momento. Para obtener más información, consulte "¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD?" a continuación. </p>

                        </li>
                        <li><p>Para proteger nuestros Servicios. Podemos procesar su información como parte de nuestros esfuerzos para mantener nuestros Servicios seguros y protegidos, incluido el monitoreo y la prevención del fraude. </p>

                        </li>
                        <li><p>Para identificar tendencias de uso. Podemos procesar información sobre cómo utiliza nuestros Servicios para comprender mejor cómo se utilizan para que podamos mejorarlos. </p>

                        </li>
                        <li><p>Para determinar la eficacia de nuestras campañas de marketing y promoción. Podemos procesar su información para comprender mejor cómo proporcionar campañas de marketing y promocionales que sean más relevantes para usted. </p>

                        </li>
                    </ul>

                </ul>

                <p>3. ¿CUÁNDO Y CON QUIÉN COMPARTIMOS SU INFORMACIÓN PERSONAL? </p>

                <p>En resumen: Podemos compartir información en situaciones específicas descritas en esta sección y/o con los siguientes terceros. </p>

                <ul>
                    <li><p>Es posible que necesitemos compartir su información personal en las siguientes situaciones: </p>

                    </li>
                    <ul>
                        <li><p>Transferencias de negocios. Podemos compartir o transferir su información en relación con, o durante las negociaciones de cualquier fusión, venta de activos de la empresa, financiación o adquisición de la totalidad o una parte de nuestro negocio a otra empresa. </p>

                        </li>
                        <li><p>Cuando usamos las API de Google Maps Platform. Es posible que compartamos su información con ciertas API de Google Maps Platform (por ejemplo, la API de Google Maps, la API de Places). Obtenemos y almacenamos en su dispositivo ("caché") su ubicación. Puede revocar su consentimiento en cualquier momento poniéndose en contacto con nosotros a través de los datos de contacto que figuran al final de este documento. </p>

                        </li>
                        <li><p>Afiliados. Es posible que compartamos su información con nuestros afiliados, en cuyo caso exigiremos a esos afiliados que cumplan con este aviso de privacidad. Las filiales incluyen nuestra empresa matriz y cualquier subsidiaria, socios de empresas conjuntas u otras empresas que controlamos o que están bajo control común con nosotros. </p>

                        </li>
                        <li><p>Socios comerciales. Podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones. </p>

                        </li>
                        <li><p>Muro de ofertas. Es posible que nuestra(s) aplicación(es) muestre un "muro de ofertas" alojado por terceros. Un muro de ofertas de este tipo permite a los anunciantes externos ofrecer moneda virtual, regalos u otros artículos a los usuarios a cambio de la aceptación y finalización de una oferta publicitaria. Dicho muro de ofertas puede aparecer en nuestra(s) aplicación(es) y mostrarse en función de ciertos datos, como su área geográfica o información demográfica. Al hacer clic en un muro de ofertas, se le dirigirá a un sitio web externo que pertenece a otras personas y abandonará nuestra(s) aplicación(es). Se compartirá un identificador único, como su ID de usuario, con el proveedor del muro de ofertas para evitar fraudes y acreditar adecuadamente su cuenta con la recompensa correspondiente. </p>

                        </li>
                    </ul>

                </ul>

                <p>4. ¿UTILIZAMOS COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO? </p>

                <p>En resumen: Podemos utilizar cookies y otras tecnologías de seguimiento para recopilar y almacenar su información. </p>

                <p>Podemos utilizar cookies y tecnologías de seguimiento similares (como balizas web y píxeles) para acceder o almacenar información. La información específica sobre cómo utilizamos dichas tecnologías y cómo puede rechazar ciertas cookies se establece en nuestro Aviso de cookies. </p>

                <p>5. ¿CUÁNTO TIEMPO CONSERVAMOS SU INFORMACIÓN? </p>

                <p>En resumen: Conservamos su información durante el tiempo que sea necesario para cumplir con los fines descritos en este aviso de privacidad, a menos que la ley exija lo contrario. </p>

                <p>Solo conservaremos su información personal durante el tiempo que sea necesario para los fines establecidos en este aviso de privacidad, a menos que la ley exija o permita un período de retención más largo (como requisitos fiscales, contables u otros requisitos legales). Ningún propósito de este aviso requerirá que conservemos su información personal durante más tiempo que el período de tiempo en el que los usuarios tienen una cuenta con nosotros. </p>

                <p>Cuando no tengamos una necesidad comercial legítima en curso para procesar su información personal, eliminaremos o anonimizaremos dicha información o, si esto no es posible (por ejemplo, porque su información personal se ha almacenado en archivos de copia de seguridad), almacenaremos de forma segura su información personal y la aislaremos de cualquier procesamiento posterior hasta que sea posible eliminarla. </p>

                <p>6. ¿CÓMO MANTENEMOS SEGURA SU INFORMACIÓN? </p>

                <p>En resumen: Nuestro objetivo es proteger su información personal a través de un sistema de medidas de seguridad organizativas y técnicas. </p>

                <p>Hemos implementado medidas de seguridad técnicas y organizativas apropiadas y razonables diseñadas para proteger la seguridad de cualquier información personal que procesamos. Sin embargo, a pesar de nuestras salvaguardas y esfuerzos para proteger su información, no se puede garantizar que ninguna transmisión electrónica a través de Internet o tecnología de almacenamiento de información sea 100% segura, por lo que no podemos prometer ni garantizar que los piratas informáticos, ciberdelincuentes u otros terceros no autorizados no puedan vencer nuestra seguridad y recopilar, acceder, robar o modificar indebidamente su información. Aunque haremos todo lo posible para proteger su información personal, la transmisión de información personal hacia y desde nuestros Servicios es bajo su propio riesgo. Solo debe acceder a los Servicios dentro de un entorno seguro. </p>

                <p>7. ¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD? </p>

                <p>En resumen: Puede revisar, cambiar o cancelar su cuenta en cualquier momento. </p>

                <p>Retirada de su consentimiento: Si nos basamos en su consentimiento para procesar su información personal, que puede ser un consentimiento expreso y/o implícito dependiendo de la ley aplicable, tiene derecho a retirar su consentimiento en cualquier momento. Puede retirar su consentimiento en cualquier momento poniéndose en contacto con nosotros utilizando los datos de contacto proporcionados en la sección "¿CÓMO PUEDE PONERSE EN CONTACTO CON NOSOTROS EN RELACIÓN CON ESTE AVISO?" a continuación. </p>

                <p>Sin embargo, tenga en cuenta que esto no afectará a la legalidad del tratamiento antes de su retirada ni, cuando la legislación aplicable lo permita, afectará al tratamiento de su información personal realizado sobre la base de motivos de tratamiento legales distintos del consentimiento. </p>

                <p>Información de la cuenta </p>

                <p>Si en algún momento desea revisar o cambiar la información de su cuenta o cancelar su cuenta, puede: </p>

                <p>Póngase en contacto con nosotros utilizando la información de contacto proporcionada. </p>

                <p>Tras su solicitud para cancelar su cuenta, desactivaremos o eliminaremos su cuenta e información de nuestras bases de datos activas. Sin embargo, podemos retener cierta información en nuestros archivos para prevenir fraudes, solucionar problemas, ayudar con cualquier investigación, hacer cumplir nuestros términos legales y/o cumplir con los requisitos legales aplicables. </p>

                <p>Si tiene preguntas o comentarios sobre sus derechos de privacidad, puede enviarnos un correo electrónico a contacto@parkup.cl. </p>

                <p>8. CONTROLES PARA LAS FUNCIONES DE NO SEGUIMIENTO </p>

                <p>La mayoría de los navegadores web y algunos sistemas operativos móviles y aplicaciones móviles incluyen una función o configuración de No rastrear ("DNT") que puede activar para indicar su preferencia de privacidad de no tener datos sobre sus actividades de navegación en línea monitoreados y recopilados. En esta etapa no se ha finalizado ninguna norma tecnológica uniforme para el reconocimiento y la aplicación de las señales DNT. Como tal, actualmente no respondemos a las señales del navegador DNT ni a ningún otro mecanismo que comunique automáticamente su elección de no ser rastreado en línea. Si se adopta un estándar para el seguimiento en línea que debamos seguir en el futuro, le informaremos sobre esa práctica en una versión revisada de este aviso de privacidad. </p>

                <p>9. ¿ACTUALIZAMOS ESTE AVISO? </p>

                <p>En resumen: Sí, actualizaremos este aviso según sea necesario para cumplir con las leyes pertinentes. </p>

                <p>Es posible que actualicemos este aviso de privacidad de vez en cuando. La versión actualizada se indicará con una fecha actualizada de "Revisado" y la versión actualizada entrará en vigencia tan pronto como sea accesible. Si realizamos cambios sustanciales en este aviso de privacidad, podemos notificárselo publicando un aviso destacado de dichos cambios o enviándole directamente una notificación. Le recomendamos que revise este aviso de privacidad con frecuencia para estar informado de cómo estamos protegiendo su información. </p>

                <p>10. ¿CÓMO PUEDE PONERSE EN CONTACTO CON NOSOTROS EN RELACIÓN CON ESTE AVISO? </p>

                <p>Si tiene preguntas o comentarios sobre este aviso, puede enviarnos un correo electrónico a contacto@parkup.cl o comunicarse con nosotros por correo postal a: </p>

                <p>MAAS SpA </p>

                <p>Calle Valdes 340, Melipilla </p>

                <p>Santiago</p>

                <p>Chile </p>

                <p>11. ¿CÓMO PUEDE REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE RECOPILAMOS DE USTED? </p>

                <p>Según las leyes aplicables de su país, es posible que tenga derecho a solicitar acceso a la información personal que recopilamos de usted, cambiar esa información o eliminarla. Para solicitar la revisión, actualización o eliminación de su información personal, visite:<a href="https://www.parkup.cl/" > </a><a href="https://www.parkup.cl" >https://www.parkup.cl</a> o escriba a contacto@parkup.cl</p>

                <br />
                </div>
            </section>



            <footer className="home-footer">
                <img
                    alt="logo"
                    src="/playground_assets/logo_parkup-1500h.png"
                    className="home-image1"
                />
                <span className="home-text29">
                    © 2023 Parkup, All Rights Reserved.
                </span>
                <div className="home-icon-group">
                    <svg viewBox="0 0 950.8571428571428 1024" className="home-icon10">
                        <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                    </svg>
                    <svg viewBox="0 0 877.7142857142857 1024" className="home-icon12">
                        <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                    </svg>
                    <svg viewBox="0 0 602.2582857142856 1024" className="home-icon14">
                        <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                    </svg>
                </div>
            </footer>

        </div>
    )
}

export default Home
